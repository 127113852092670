<template>
  <div class="root">
    <div class="form">
      <form
        action=""
        @submit.prevent="handleSubmit"
      >
        <h2 class="title">Authorization</h2>

        <div class="formGroup">
          <el-input
            required="true"
            v-model="login"
            type="email"
            placeholder="Email"
          />
        </div>

        <div class="formGroup">
          <el-input
            required="true"
            type="password"
            v-model="password"
            placeholder="Password"
          />
        </div>

        <div class="formGroup errors">
          {{ errorMessage }}
        </div>

        <div class="formGroup">
          <el-button
            class="submit-button"
            native-type="submit"
          >
            Submit
          </el-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import {ref} from "vue";
import { useAuthStore } from "@/store/auth";
import { useRouter } from 'vue-router';

const router = useRouter()

let login = ref('')
let password = ref('')
let errorMessage = ref('')

function handleSubmit() {
  const auth = useAuthStore()

  auth.login({ email: login.value, password: password.value })
      .then(() => {
        router.push('/home')
      })
      .catch(error => {
        console.error(error)

        const { response } = error
        const { data } = response

        errorMessage.value = typeof data === 'object'
          ? Object.values(data)[0]
          : data
      })
}

</script>
<style lang="scss" scoped>
.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: #BDE0FE;
}

.form {
  width: 300px;
  max-width: 100%;
  border-radius: 12px;
  background: #A2D2FF;
  padding: 24px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.formGroup {
  margin: 12px 0;
}

.submit-button {
  margin-top: 30px;
  width: 100%;
}

.errors {
  color: rgba(255,50, 50, 1);
}
</style>
