import {defineStore} from "pinia";
import {
  approveMapObject,
  deleteMapObject,
  getMapObjectsForModeration,
  getTypesAndSubtypes,
  updateMapObjectLocation,
} from "@/api/endpoints/poi";
import {useAuthStore} from "@/store/auth";
import {commaSeparate} from "@/utils/common";

export const usePoisStore = defineStore('pois', {
  state: () => ({
    initialized: false,
    loading: false,

    list: [],

    pages_count: 0,
    current_page: 0,
    total_objects_count: 0,

    poi_subtypes: {},
    road_event_subtypes: {},

    // active_poi_id: null,

    active_moderator_id: null,
    active_type_slug: "poi",
    active_subtype_slug: null,
    type: null
  }),
  getters: {
    canLoadMore() {
      return this.current_page < this.pages_count
    }
  },
  actions: {
    async loadList(params) {
      if(this.loading) {
        return
      }

      const auth = useAuthStore()
      this.loading = true

      return getMapObjectsForModeration({
        user_id: params.user_id ?? auth.me.id,
        moderation_state: false,
        page: params.page ?? 1,
        types: params.types ?? commaSeparate(this.active_type_slug),
        subtypes: params.subtypes ?? commaSeparate(this.active_subtype_slug)
      })
        .then(({ data }) => {
          // debugger
          this.current_page = data.current_page
          this.total_objects_count = data.total
          this.pages_count = data.last_page

          if(params.append) {
            const existingIds = this.list.map(item => item._id)
            const newObjects = data.objects.filter(item => !existingIds.includes(item._id))

            console.log('ID', existingIds)
            console.log(newObjects)

            this.list = [...this.list, ...newObjects]

            return
          }

          this.list = data.objects
        })
        .finally(() => {
          this.loading = false
        })
    },

    async loadSybtypes() {
      return getTypesAndSubtypes()
        .then(({data}) => {
          this.poi_subtypes = data.poi
          this.road_event_subtypes = data.road_event
        })
    },
    async deleteObject({ id }) {
      return deleteMapObject({id})
        .then(() => {
          this.list = this.list.filter(item => item._id !== id)
        })
    },
    async approveObject({ id }) {
      return approveMapObject({id})
        .then(() => {
          this.list = this.list.filter(item => item._id !== id)
        })
    },
    async updateObjectCoordinates({ id, ...rest }) {
      return updateMapObjectLocation({id, ...rest})
        .then(() => {

        })
    }
  }
})