<template>
  <div class="wrapper">
    <POIList
      class="list"
      :list="list"
      :selectedItem="selectedItem"
      @loadMore="handleLoadMore"
      @selectItem="handleSelectItem"
      @deleteItem="handleDeleteItem"
      @approveItem="handleApproveItem"
    />
    <POIDescription :item="selectedItem"/>
    <POIMap
      ref="map"
      :movements="pointMovements"
      :pointList="visibleItems"
      @movePoint="handleMovePoint"
      @saveMovements="handleSaveMovements"
    />
  </div>
</template>
<script setup>
import {computed, reactive, ref, toRefs, watch} from 'vue';
import POIList from '@/components/POI/POIList';
import POIMap from '@/components/POI/POIMap';
import POIDescription from '@/components/POI/POIDescription';
import {useAuthStore} from "@/store/auth";
import {useProfilesStore} from "@/store/profiles";
import {usePoisStore} from "@/store/pois";
import {getPOIByPolygon, updateMapObjectLocation} from "@/api/endpoints/poi";
import {debounce} from 'lodash';

const profilesStore = useProfilesStore()
const poisStore = usePoisStore()
let {
  list,
  initialized,
  active_subtype_slug,
  type_slug,
  active_type_slug,
  active_moderator_id,
  pages_count,
  current_page,
  canLoadMore
} = toRefs(poisStore)

let { me } = toRefs(useAuthStore())

let selectedItem = reactive({})
let itemsCloseToSelected = reactive([])
let map = ref(null)

let pointMovements = ref({})

if(!initialized.value) {
  initialized = true

  profilesStore.loadModerators()
  poisStore.loadSybtypes()

  poisStore.loadList({
    page: 1
  })
}

if(!active_moderator_id.value) {
  active_moderator_id.value = me.value.id
}

watch([active_subtype_slug, active_type_slug], () => {
  poisStore.loadList({
    page: 1,
    types: active_type_slug.value,
    subtypes: active_subtype_slug.value.join(',')
  })
})

watch([active_moderator_id], () => {
  poisStore.loadList({
    page: 1,
    user_id: active_moderator_id.value,
    types: active_type_slug.value,
    subtypes: active_subtype_slug.value.join(',')
  })
})

const visibleItems = computed(() => {
  return [
      ...list.value,
    ...itemsCloseToSelected.filter(item => {
      return list.value.findIndex(({ _id }) => item._id === _id) !== -1
    })
  ]
})

function handleSelectItem(event) {
  selectedItem.value = event
  map.value.setItemActive(event)
  map.value.gotoSite(event)

  const extent = map.value.getMapExtent()

  getPOIByPolygon({
    zoom: extent.zoom,
    per_page: 100,
    polygon: extent.extentCoordinates.slice(0, 3)
        .map(item => item.reverse().join(','))
        .join(';')
  })
    .then(({ data }) => {
      itemsCloseToSelected.value = data
    })
}

function handleLoadMore() {
  if(!canLoadMore.value) return

  poisStore.loadList({
    page: current_page.value + 1,
    append: true
  })
}

const handleMovePoint = debounce(({ item, coordinates }) => {
  pointMovements.value[item._id] = coordinates
}, 1)

function handleSaveMovements() {
  Object.keys(pointMovements.value).forEach(itemId => {
    updateMapObjectLocation({
      id: itemId,
      type: 'Point',
      coordinates: pointMovements.value[itemId].reverse()
    })
  })
  pointMovements.value = {}
}
</script>
<style scoped>
.list {
  /*min-width: 200px;*/
}

.wrapper {
  display: flex;
  align-items: stretch;
  height: 100%;
}
</style>