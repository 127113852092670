<template>
  <el-header class="header">
    <POIHeader v-if="route.name === 'poi'"/>
  </el-header>
</template>
<script setup>
import {onMounted} from "vue";
import {useRoute} from 'vue-router';
import POIHeader from '@/components/POI/POIHeader'

const route = useRoute()

onMounted(() => {
  console.log(route)
})

</script>
<style scoped>
.header {
  min-width: 100%;
  background-color: #c6e2ff;
}
</style>