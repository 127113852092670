import { createRouter, createWebHistory } from "vue-router"
import Users from "../views/Users"
import POI from "../views/POI/POI"
import Auth from '../views/Auth'
import TheHome from '../components/layout/Home/TheHome'
import {useAuthStore} from "@/store/auth"

const routes = [
  {
    path: '/',
    redirect: { name: 'auth' }
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth
  },
  // {
  //   path: '/',
  //   redirect: { name: 'poi' }
  // },
  {
    path: '/home',
    name: 'home',
    redirect: { name: 'poi' },
    component: TheHome,

    children: [
      {
        path: "poi",
        name: "poi",
        component: POI,
      },
      {
        path: "users",
        name: "users",
        component: Users,
      },
    ],
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore()

  if (to.name !== 'auth' && (!auth.access_token || !['moderator', 'admin'].includes(auth.me?.role))) next({ name: 'auth' })
  else next()
});

export default router;
