import axios from 'axios'
import { useAuthStore } from "@/store/auth";
import {OWN_BACKEND_PREFIXES} from "@/config/api";

export const POITransport = axios.create({
    baseURL: process.env.VUE_APP_OWN_BACKEND_BASE_URL + '/' + OWN_BACKEND_PREFIXES.MAP_POI,
    headers: {
        // "Authorization": "Bearer " + auth.access_token,
        // "ContentType": "application/json",

        // Accept: "application/json",
        common: {
            "Content-Type": "application/json",
        }
    }
})

POITransport.interceptors.request.use(
  (config) => {
      const auth = useAuthStore()

      config.headers = {
          ...config.headers,
          Authorization: `Bearer ${auth.access_token}`
      }

      return config
  },
  (error) => {
      return Promise.reject(error)
  }
)
