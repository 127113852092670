import {createApp, watch} from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from 'pinia'

import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import { VueQueryPlugin } from "vue-query";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

createApp(App)
    .use(router)
    .use(pinia)
    .use(ElementPlus)
    .use(VueQueryPlugin, {
        queryClientConfig: {
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                },
            },
        },
    })
    .mount("#app");

// pinia.use(({ store }) => {
//   store.$subscribe(event => {
//     if(event.storeId === 'auth') {
//       console.log('STORE', store.$state)
//     }
//     // react to store changes
//   })
// })