<template>
  <el-aside class="sidebar" width="200px">
    <el-menu class="items">
      <router-link :to="{ name: 'poi' }" class="item">
        <el-menu-item index="1">
          <span>POI</span>
        </el-menu-item>
      </router-link>

      <router-link :to="{ name: 'users' }" class="item">
        <el-menu-item index="2">
          <span>Users</span>
        </el-menu-item>
      </router-link>
    </el-menu>
  </el-aside>
</template>
<style scoped>
.sidebar {
  background: #d9ecff;
  /*padding-top: 60px;*/
}
.items {
  background: transparent;
}
.item {
  text-decoration: none;
  user-select: none;
  background: transparent;
  height: 40px;
}
</style>
