<template>
  <router-view/>
</template>
<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Roboto, Arial, sans-serif;
}

.el-scrollbar__view {
  display: block !important;
}
</style>
