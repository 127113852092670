<template>
  <div
    ref="containerRef"
    class="poi-list__container"
  >
    <ElTableV2
      v-if="ready"
      :columns="columns"
      :data="props.list"
      :width="300"
      :height="height"
      :sort-by="sort"
      @column-sort="onSort"
      :header-height="0"
      fixed
      :estimated-row-height="100"
      :row-event-handlers="{ onClick: handleClick }"
      :footer-height="canLoadMore ? 50 : 0"
    >
      <template #footer>
        <button
          v-if="canLoadMore"
          @click="handleLoadMore"
          class="poi-list__footer"
        >
          Load more
        </button>
      </template>
    </ElTableV2>
  </div>
</template>
<script setup lang="jsx">
import {onMounted, ref, watch, defineProps, onUnmounted, toRefs, computed} from "vue";
import { TableV2SortOrder } from 'element-plus';
import POIItem from './POIItem'
import {usePoisStore} from "@/store/pois";
const emit = defineEmits(['selectItem', 'loadMore'])
const props = defineProps(['list', 'selectedItem'])

const poisStore = usePoisStore()
const { pages_count, current_page, canLoadMore } = toRefs(poisStore)

let ready = ref(false)
onMounted(() => ready.value = true)

const containerRef = ref([])
let height = ref(0)
function updateHeight() {
  height.value = Number.parseInt(window.getComputedStyle(containerRef.value).height)
}
watch([containerRef], updateHeight)
window.addEventListener('resize', updateHeight)
onUnmounted(() => window.addEventListener('resize', updateHeight))

let columns = [
  {
    key: '_id',
    dataKey: '_id',
    title: '_id',
    width: 300,
    cellRenderer: ({  rowData }) => <POIItem
      active={props.selectedItem?.value?._id === rowData._id}
      item={rowData}
    />,
  },
]

let sort = {
  key: 'sub_type',
  order: TableV2SortOrder.ASC
}
function onSort(sortBy) {
  sort = sortBy
}

function handleClick({ rowData }) {
  emit('selectItem', rowData)
}

function handleLoadMore() {
  emit('loadMore')
}
</script>
<style lang="scss" scoped>
.poi-list {
  &__container {
    height: 100%;
  }

  &__footer {
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: aliceblue;
  }
}
</style>