import { ClientJS } from 'clientjs'
import {memoize} from 'lodash'
const UAParser = require('ua-parser-js')

export const OS = {
    Windows: 'windows',
    Ios: 'ios',
    Android: 'android',
    MacOS: 'macos',
    Linux: 'unix'
}

export const getDeviceInfo = memoize(function () {
    const client = new ClientJS()
    let os = ''

    if (client.isMobile()) {
        os = client.isMobileIOS() ? OS.Ios : OS.Android
    } else {
        if (client.isMac()) {
            os = OS.MacOS
        } else if (client.isWindows()) {
            os = OS.Windows
        } else {
            os = OS.Linux
        }
    }
    return {
        installation_id: String(client.getFingerprint()) ?? '',
        phone_model: client.getBrowser() ?? '',
        system_version: client.getBrowserVersion() ?? '',
        app_version: '1.0',
        os_type: os
    }
})

export const getFingerprintClientside = memoize(() => {
    const client = new ClientJS()

    return client.getFingerprint()
})
