<template>
  <div
    v-loading.lock="!isReady"
    class="root"
  >
    <div class="filter">
      Filter:
      <el-select
        v-model="filter.product"
        placeholder="Select"
        size="large"
      >
        <el-option
          v-for="item in products"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>

      <ElCheckbox v-model="filter.withoutNickname">
        Without nickname only
      </ElCheckbox>
    </div>


    <el-collapse v-model="activeNames">
      <el-collapse-item
        v-for="([date, list]) in groupedByDayUsers"
        :title="formatDate(date)"
        :name="date"
      >
        <div
          v-if="activeNames.includes(date)"
          class="list"
        >
          <UserList :users="list"/>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc"
import {loadProfiles} from "@/api/endpoints/profiles";
import UserList from '@/components/UserList'
dayjs.extend(utc)

const ITEMS_PER_PAGE = 200
const LOAD_NEW_INTERVAl = 15000

export default {
  components: {
    UserList
  },

  computed: {
    products() {
      return ['all', 'business', 'driver']
    },

    users() {
      return Object.values(this.rawUsers).filter(user => {
        if (this.filter.withoutNickname === true && user.nickname) {
          return false
        }
        if (this.filter.product !== 'all' && user.first_product !== this.filter.product) {
          return false
        }
        return true
      })
    },

    groupedByDayUsers() {
      const map = {}

      this.users.forEach(user => {
        const day = dayjs.utc(user.created_at).startOf('day')
        map[day.toString()] ??= []
        map[day.toString()].push(user)
      })

      const entries = Object.entries(map)
      entries.sort((a, b) => {
        return new Date(b[0]) - new Date(a[0])
      })

      return entries
    },
  },

  data() {
    return {
      loadNewTimeoutId: null,

      isMounted: false,
      isReady: false,

      activeNames: [],

      filter: {
        withoutNickname: false,
        product: 'all'
      },

      rawUsers: {}
    }
  },

  methods: {
    async loadAllUsers(maxCount = Infinity) {
      let total = 0
      let list = []

      do {
        const { data } = await loadProfiles({
          offset: list.length,
          limit: Math.min(ITEMS_PER_PAGE, maxCount)
        })

        total = data.total
        list.push(...data.items)
      } while(total > list.length && list.length < maxCount)

      list.forEach(profile => {
        this.rawUsers[profile.id] = profile
      })

      // if(data.length >= ITEMS_PER_PAGE && this.isMounted === true) {
      //   this.loadAllUsers({
      //     offset: params.offset + ITEMS_PER_PAGE,
      //     limit: ITEMS_PER_PAGE
      //   })
      // } else {
        this.isReady = true

        this.loadNewTimeoutId = setTimeout(() => {
          if(this.isMounted) {
            this.loadAllUsers(200)
          }
        }, LOAD_NEW_INTERVAl)
    },

    formatDate(date) {
      return dayjs(date).format('DD.MM.YYYY')
    }
  },

  created() {
    this.isMounted = true

    this.loadAllUsers()
  },

  beforeUnmount() {
    this.isMounted = false
    if(this.loadNewTimeoutId) {
      clearTimeout(this.loadNewTimeoutId)
    }
  }
}
</script>
<style scoped>
.root {
  height: 100%;
  width: 100%;
}
.filter {
  padding: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.list {
  max-width: calc(100vw - 200px);
  overflow: scroll;

  box-sizing: border-box;
}
</style>
<style>
.el-collapse-item__content {
  padding: 0;
}
</style>
