<template>
  <div class="poi-description">
    <el-table :data="data" style="width: 100%">
      <el-table-column prop="name" label="Name">
        <template #default="scope">
          <b>{{ scope.row.name }}</b>
        </template>
      </el-table-column>
      <el-table-column prop="value" label="Value" />
    </el-table>
  </div>
</template>
<script setup>
import {computed, toRef, toRefs, watch} from "vue";

let props = defineProps(['item'])

// let data = []

let data = computed(() => {
  if(typeof props.item.value !== 'object') return []

  return Object.keys(props.item.value)
      .map(key => ({ name: key, value: props.item.value[key] }))
      .map(item => {
        if(Array.isArray(item.value)) item.value = item.value.join(', ')
        if(item.value !== null && typeof item.value === 'object') item.value = JSON.stringify(item.value)

        return item
      })
      .filter(({ value }) => value !== null && value !== '')
})
watch(data, () => console.log(data))

// watch(props.item, () => console.log('FF', item))

</script>
<style lang="scss" scoped>
.poi-description {
  width: 500px;
  max-height: 100%;
  overflow-y: scroll;
}
</style>