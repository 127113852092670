<template>
  <div class="poi-header">
<!--    <div class="source__wrapper">-->
<!--      <el-button :text="true">-->
<!--        Requests-->
<!--      </el-button>-->
<!--      <div class="divider"></div>-->
<!--      <el-button type="info" :text="true">-->
<!--        Database-->
<!--      </el-button>-->
<!--    </div>-->
    <el-select
      v-model="active_moderator_id"
    >
      <el-option
        v-for="profile in moderators"
        :value="profile.id"
        :label="profile.first_name"
      />
    </el-select>

    <el-select
      v-model="active_subtype_slug"
      multiple
      collapse-tags
    >
      <el-option
        v-for="slug in Object.keys(poi_subtypes)"
        :value="slug"
        :label="slug"
      />
    </el-select>

    <div class="stats">
      Remain {{ total_objects_count }}
    </div>
  </div>
</template>
<script setup>
import {useProfilesStore} from "@/store/profiles";
import {ref, toRef, toRefs, watch} from "vue";
import {usePoisStore} from "@/store/pois";

const { moderators } = toRefs(useProfilesStore())
const {
  poi_subtypes,
  total_objects_count,
  active_subtype_slug,
  active_type_slug,
  active_moderator_id,
} = toRefs(usePoisStore())

// let moderators = ref(profiles.moderators)

// let subtype = ref(null)
//
// watch(subtype, () => {
//   active_subtype_slug.value = subtype
// })


</script>
<style scoped>
.poi-header {
  min-width: 100%;
  background-color: #c6e2ff;
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  gap: 30px;
  height: 100%;
  width: 100%;
}

.source__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.divider {
  width: 1px;
  height: 20px;
  background-color: grey;
  margin: 0 4px;
}

.stats {

}
</style>