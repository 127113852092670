import {POITransport} from "@/api/transport/poi.transport";

export function getPOIBySphere(params) {
    return POITransport.get('/mapobjects/sphere', { params })
}

export function getPOIByPolygon(params) {
    return POITransport.get('/mapobjects/polygon', { params })
}

export function getMapObjectsForModeration(params) {
    return POITransport.get('/support/mapobjects', { params })
}

export function deleteMapObject({ id, ...params }) {
    return POITransport.delete(`/support/mapobject/${id}`, { params, data: {} })
}

export function approveMapObject({ id, ...rest }) {
    return POITransport.patch(`/support/mapobject/${id}/approve`, rest)
}

export function getTypesAndSubtypes(params) {
    return POITransport.get('/types-subtypes', { params })
}

export function updateMapObjectLocation({ id, ...rest }) {
    return POITransport.patch(`/support/mapobject/${id}/location`, rest)
}