import { useAuthStore } from '@/store/auth'
import axios from "axios"
import {OWN_BACKEND_PREFIXES} from "@/config/api";

/**
 * Http client for requests to Roolz Identity Server
 */
export const identityTransport = axios.create({
    baseURL: process.env.VUE_APP_OWN_BACKEND_BASE_URL + '/' + OWN_BACKEND_PREFIXES.IDENTITY,
    timeout: 30000,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
})
// Change request data/error here
identityTransport.interceptors.request.use(
    (config) => {
        const auth = useAuthStore()

        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${auth.access_token}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
