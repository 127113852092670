<template>
  <div
    class="poi-item"
    :class="{
      'poi-item--active': props.active
    }"
  >
    <div class="poi-item__title">
      <img
        :src="getIconUrlBySubtype(props.item.sub_type)"
        alt=""
        class="poi-item__icon"
      >
      {{ props.item.title }}
    </div>
    <div class="poi-item__country">
      Belarus
    </div>
    <div class="poi-item__actions">
      <ElButton
        type="success"
        @click.stop="handleAllow"
      >
        Approve
      </ElButton>
      <ElButton
        type="danger"
        @dblclick.stop="handleDelete"
      >
        Remove
      </ElButton>
    </div>
  </div>
</template>
<script setup>
import {defineProps, toRefs} from "vue";
import {approveMapObject, deleteMapObject} from "@/api/endpoints/poi";
import {usePoisStore} from "@/store/pois";
import {getIconUrlBySubtype} from "@/utils/poi";

const props = defineProps(['item', 'active'])

const poisStore = usePoisStore()
const { active_poi_id } = toRefs(usePoisStore())

function handleAllow() {
  poisStore.approveObject({
    id: props.item._id
  })
}

function handleDelete() {
  poisStore.deleteObject({
    id: props.item._id
  })
}
</script>
<style lang="scss" scoped>
.poi-item {
  width: 100%;
  text-align: left;

  &__icon {
    width: 16px;
    margin-right: 5px;
  }

  &--active {
    .poi-item__icon {
      transition: 0.3s all;
      //animation: 2s heartbeat infinite;
      transform: translateY(-50%) scale(1.5);
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>* {
      flex-grow: 1;
    }
  }

  &__title {
    font-weight: 700;
    display: flex;
    align-items: center;
  }
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  25%
  {
    transform: scale( 1 );
  }
  50%
  {
    transform: scale( .75 );
  }
  70%
  {
    transform: scale( 1 );
  }
  100%
  {
    transform: scale( .75 );
  }
}
</style>