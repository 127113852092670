<template>
  <div class="map" id="map">
    <el-button
      v-show="Object.keys(movements)?.length"
      @click.stop="handleSaveMovements"
      class="save-movements"
    >
      Save movements
    </el-button>
  </div>
</template>
<script setup>
import {defineExpose} from "vue";
import * as proj from 'ol/proj';
import Select from 'ol/interaction/Select';
import Translate from 'ol/interaction/Translate';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import Point from 'ol/geom/Point';
import {fromExtent} from 'ol/geom/Polygon';
import {getTopLeft, getTopRight, getBottomLeft, getBottomRight} from 'ol/extent'
import 'ol/ol.css';
// import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ'
import { getRandomColor } from "@/utils/color";
import Collection from 'ol/Collection'

import {Circle as CircleStyle, Fill, Stroke, Style, Icon} from 'ol/style';
import {onMounted, reactive, watch} from "vue";
import {getIconUrlBySubtype} from "@/utils/poi";

const DEFAULT_ZOOM = 3

const emit = defineEmits(['viewportChange', 'movePoint', 'saveMovements'])
const props = defineProps(['pointList', 'movements'])

const data = reactive({
  map: null,
  source: null,
  features: [],
  featuresLayer: null,

  cursor: 'default'
})

function getMapExtent() {
  let extent = data.map.getView().calculateExtent(data.map.getSize())
  extent = proj.transformExtent(extent, 'EPSG:3857', 'EPSG:4326');

  return {
    zoom: data.map.getView().getZoom(),
    extentCoordinates: [getTopLeft(extent), getTopRight(extent), getBottomLeft(extent), getBottomRight(extent)]
  }
}

function setItemActive({ _id }) {
  // return
  const feature = data.features.find(feature => feature?.site?._id === _id)

  if(feature) {
    resetStyles()

    feature.setStyle(new Style({
      image: new Icon({
        scale: 0.8,
        anchorOrigin: 'top-left',
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',

        src: getIconUrlBySubtype(feature.site.sub_type)
      })
    }))
  }
}

function convertCoordinatesToLatLon(coords) {
  return proj.transform(coords, 'EPSG:3857', 'EPSG:4326');
}

const styles = {
  'geoMarker': new Style({
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({color: 'black'}),
      stroke: new Stroke({
        color: 'white',
        width: 2,
      }),
    }),
    zIndex: 10
  }),
  selected: new Style({
    image: new CircleStyle({
      radius: 8,
      fill: new Fill({color: 'black'}),
      stroke: new Stroke({
        color: 'black',
        width: 2,
      }),
    }),
  }),
  blinkFrom: new Style({
    image: new CircleStyle({
      radius: 8,
      fill: new Fill({color: 'white'}),
      stroke: new Stroke({
        color: 'black',
        width: 2,
      }),
    }),
  }),
  blinkTo: new Style({
    image: new CircleStyle({
      radius: 8,
      fill: new Fill({color: 'black'}),
      stroke: new Stroke({
        color: 'black',
        width: 2,
      }),
    }),
  }),
  zIndex: 9999999
};
styles.selected.setZIndex(999999)

function gotoSite(site) {
  setTimeout(() => {
    let view = data.map.getView()

    let zoom = view.getZoom()
    view.animate({
      center: proj.fromLonLat([site.location.coordinates[1], site.location.coordinates[0]], 'EPSG:3857'),
      duration: 1000,
      zoom: 17 // Math.min(17, site.meta.zoom_only[1] ?? 0)
    })

    setTimeout(() => {
      emit('zoomChange', getMapExtent())
    }, 1000)
  })
}

function setCursor(cursor) {
  data.cursor = cursor
}

function clearBlinks() {
  this.features.forEach(feature => {
    if (feature?.blinkIntervals) {
      feature?.blinkIntervals.filter(interval => clearInterval(interval) && false)
      feature.resetStyle()
    }
  })
}

function resetStyles() {
  data.features.forEach(feature => feature.resetStyle())
}

function blinkSite(site) {
  clearBlinks()
  resetStyles()

  let feature = features.find(feature => feature.site.id === site.id)
  if (feature) {
    feature.setStyle(styles.selected)
  }

  if (!feature?.blinkIntervals) {
    feature.blinkIntervals = []
  }
  feature.blinkIntervals.push(setInterval(() => feature.setStyle(styles.blinkFrom), 500))
  feature.blinkIntervals.push(setInterval(() => feature.setStyle(styles.blinkTo), 1000))
}

function dropSite(site) {
  let c = features.reduce((c, f) => {
    if (f.site.id == site.id) {
      c.push(f)
    }
    return c
  }, [])

  c.forEach(feature => {
    data.source.removeFeature(feature)
  })
}

function updateSites(sites) {
  data.features.forEach(feature => {
    let modifiedSite = sites.find(({id}) => id === feature.site.id)

    if (modifiedSite) {
      Object.assign(feature.site, modifiedSite)

      feature.setGeometry(new Point(proj.fromLonLat([
        feature.site.coordinates.y,
        feature.site.coordinates.x
      ])))
    }
  })
}

function drawMap() {
  const vectorLayer = new VectorLayer({
    // source,
    style: feature => {
      return new Style({
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#7e69a6'
          }),
          stroke: new Stroke({
            color: 'white',
            width: 2,
          }),
        })
      });
    },
  });

  data.featuresLayer = vectorLayer

  data.map = new Map({
    layers: [
      new TileLayer({
        // source: new OSM(),
        source: new XYZ({
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          maxZoom: 19
        })
      }),
      vectorLayer
    ],
    target: 'map',
    view: new View({
      center: proj.fromLonLat([27.567444, 53.893009], 'EPSG:3857'),
      zoom: 10,
    }),
  });

  // this.source = source

  // data.map.on('singleclick', e => {
  //   this.$emit('selectPoint', proj.transform(e.coordinate, 'EPSG:3857', 'EPSG:4326'))
  // })

  data.map.on('moveend', () => {
    emit('viewportChange', getMapExtent())
  })

  // const selectFeature = new Select({
  //   style: styles.selected
  // })
  // selectFeature.on('select', e => {
  //   if (!e.selected?.[0]?.site) {
  //     return
  //   }
  //   // this.$emit('selectSite', e.selected[0].site)
  //   // this.features.forEach(feature => feature.resetStyle())
  //   e.selected[0].setStyle(styles.selected)
  // })
  // data.map.addInteraction(selectFeaturse)
}

function handleSaveMovements() {
  emit('saveMovements')
}

watch(() => props.pointList, () => {
  console.log('LIST')
  let features = props.pointList.map(site => {
    let feature = new Feature({
      geometry: new Point(proj.fromLonLat([site.location.coordinates[1], site.location.coordinates[0]])),
      name: 'Here should be name'
    })
    feature.site = site
    // let style = new Style({
    //   image: new CircleStyle({
    //     radius: 7,
    //     fill: new Fill({
    //       color: '#7e69a6'
    //     }),
    //     stroke: new Stroke({
    //       color: "black",
    //       width: 2
    //     }),
    //   }),
    // })
    let style = new Style({
      image: new Icon({
        scale: 0.5,
        anchorOrigin: 'top-left',
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',

        src: getIconUrlBySubtype(site.sub_type)
      })
    })
    style.setZIndex(10)
    feature.resetStyle = function() {
      this.setStyle(style)
    }
    feature.resetStyle()

    const translate = new Translate({
      features: new Collection([feature])
    })
    translate.on('translating', function (evt) {
      emit('movePoint', {
        item: site,
        coordinates: convertCoordinatesToLatLon(evt.coordinate)
      })
      // console.log(evt.coordinate)
      // line.setCoordinates([coordMarker2, evt.coordinate]);
    });

    data.map.addInteraction(translate)

    feature.translateInteraction = translate

    return feature
  })
  data.features = features

  console.log(features, props.pointList)

  let source = new VectorSource({
    features,
  })

  data.featuresLayer.setSource(source)
})

onMounted(() => drawMap())

defineExpose({
  gotoSite,
  getMapExtent,
  setItemActive
})

</script>
<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
  //background-color: green;
  position: relative;
}
.save-movements {
  transition: 0.3s all;
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 100;
}
</style>