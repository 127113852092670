<template>
  <div class="userList">
<!--    <el-auto-resizer>-->
<!--      <template #default="{ height, width }">-->
<!--        <el-table-v2-->
<!--          :columns="columns"-->
<!--          :data="users"-->
<!--          :width="width"-->
<!--          :height="500"-->
<!--          :sort-by="sortState"-->
<!--          fixed-->

<!--          @column-sort="onSort"-->
<!--        />-->
<!--      </template>-->
<!--    </el-auto-resizer>-->

    <el-table
        stripe
        :data="preparedUsers"
        ref="table"
        highlight-current-row
        :height="tableHeight"
        @current-change="handleCurrentChange"
        :cell-style="getCellStyle"
    >
      <el-table-column
          v-for="{ name, property, fixed, width } in columns"
          :prop="name"
          :label="name"
          :fixed="fixed"
          :width="width || 155"
      >
        <template #default="scope">
          <div v-html="get(scope.row, property || name)"/>
        </template>
      </el-table-column>


    </el-table>
  </div>
</template>
<script>
import {TableV2SortOrder} from "element-plus";
import {get, clone, cloneDeep} from 'lodash';


export default {
  props: ['users'],

  data() {
    return {
      sortState: {
        key: 'created_at',
        order: TableV2SortOrder.DESC,
      },
    }
  },

  computed: {
    sortedUsers() {
      const { key, order } = this.sortState

      const result = [...this.users]

      result.sort((a, b) => {
        if(order === 'desc') {
          const tmp = b
          b = a
          a = tmp
        }

        if(key.endsWith('_at') || key.endsWith('_date')) {
          return new Date(a[key]) - new Date(b[key])
        } else if(typeof a[key] === 'string' || typeof b[key] === 'string') {
          return String(a[key]).localeCompare(String(b[key]))
        } else {
          return a[key] - b[key]
        }
      })

      return result
    },

    preparedUsers() {
      return [...this.sortedUsers].map(originalRow => {
        const row = cloneDeep(originalRow)

        if(!row.profile_view_info) {
          return row
        }

        const { phone, email } = row
        const { own_phone, own_email, work_phone, work_email, last_name } = row.profile_view_info

        if(own_phone && own_phone !== phone) {
          row.phone += '<br/>' + own_phone
        }

        // delete row.first_phone
        // delete row.second_phone

        if(own_email && own_email !== email) {
          row.email += '<br/>' + own_email
        }

        // delete row.first_email
        // delete row.second_email

        if(last_name) {
          row.profile_view_info.first_name += ' ' + last_name
        }

        // delete row.last_name

        return row
      })
    },

    tableHeight() {
      return window.innerHeight * 0.8
    },
    columns() {
      return [
        { name: 'created_at', fixed: true},
        { name: 'email', },
        { name: 'phone', },
        // { name: 'first_phone', },
        {name: 'personal_companies'},
        // { name: 'first_email', },
        { name: 'full name', property: 'profile_view_info.first_name'  },
        // { name: 'last_name', },
        { name: 'nickname', },
        { name: 'last_login_at', },
        { name: 'active_space_company_id', },

        { name: 'id', },
      ]
    }

    // columns() {
    //   if(!this.users.length) {
    //     return {}
    //   }
    //
    //   return Object.keys(this.users[0]).map(name => ({
    //     id: name === 'created_at' ? 0 : 1,
    //     key: name,
    //     dataKey: name,
    //     title: name,
    //     width: 200,
    //     sortable: true
    //   }))
    // }
  },

  methods: {
    get,

    onSort(sortBy) {
      this.sortState = sortBy
    },
    // sortUsers() {
    //   const { key, order } = this.sortState
    //
    //   this.users.sort((a, b) => {
    //     if(order === 'desc') {
    //       const tmp = b
    //       b = a
    //       a = tmp
    //     }
    //
    //     if(key.endsWith('_at') || key.endsWith('_date')) {
    //       return new Date(a[key]) - new Date(b[key])
    //     } else if(typeof a[key] === 'string' || typeof b[key] === 'string') {
    //       return String(a[key]).localeCompare(String(b[key]))
    //     } else {
    //       return a[key] - b[key]
    //     }
    //   })
    // },
    handleCurrentChange(row) {
      this.$refs.table.setCurrentRow(row)
    },
    getCellStyle(cell) {
      if(cell.column.property === 'active_space_company_id' && !!cell.row.active_space_company_id) {
        return {
          'background-color': '#409EFF',
          color: 'white'
        }
      }
      // console.log(cell)
    }
  },

  watch: {
    sortState() {
      this.sortUsers()
    },
    // users: {
    //   handler() {
    //     console.log('CALL HANDLER')
    //     this.sortUsers()
    //   },
    //   deep: true
    // }
  },

}
</script>
<style scoped>
.userList {
  overscroll-behavior-y: none;
}
</style>
