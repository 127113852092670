import {defineStore} from "pinia";
import {loadProfiles} from "@/api/endpoints/profiles";

export const useProfilesStore = defineStore('profiles', {
  state: () => ({
    moderators: []
  }),
  actions: {
    async loadModerators() {
      loadProfiles(undefined, { roles: ['moderator'] })
        .then(({ data }) => {
          this.moderators = data

          console.log('MMM', data)
        })
    }
  },
  persist: true
})
