import {defineStore} from "pinia";
import { loginByEmailPassword } from '@/api/endpoints/auth';
import {getDeviceInfo} from "@/utils/device";
import {usePoisStore} from "@/store/pois";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        access_token: '',
        me: null
    }),
    actions: {
        async login({ email, password }) {
            return loginByEmailPassword({
              auth_email: email,
              auth_password: password,
              device: getDeviceInfo()
            })
              .then(({ data }) => {
                this.access_token = data.auth_token.token
                this.me = data.profile

                return data
              })
        }
    },
  persist: true
})
